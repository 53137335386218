import { useEffect } from 'react';

import cloneDeep from 'lodash.clonedeep';
import unset from 'lodash.unset';
import moment from 'moment';

import type { AppError, S3Schema, UIFormValues } from '../../@types/types';

type FormValues = {
  office?: string;
  unapproved_security?: string;
  client_disclaimer_signed?: string;
  unapproved_security_name_section?: string;
  contract_type?: string;
  contract_type_section_name?: string;
  declared_to_lineslip?: string;
  profit_commission?: string;
  premium_payment?: {
    settlement_due_date?: string;
    due_to_ed_date?: string;
  };
} & UIFormValues;

type SetFormValuesFn = (values: UIFormValues) => void;
type GetFormStateFn = { (): FormValues; (): any };
type SetFormErrorsFn = (errors: AppError[]) => void;

export const useEocOpenMarketBermudaEffects = (
  schema: S3Schema,
  formValues: FormValues,
  formErrors: AppError[],
  setFormValues: SetFormValuesFn,
  getFormState: GetFormStateFn,
  setFormErrors: SetFormErrorsFn
): void => {
  useEffect(() => {
    if (!schema || !schema.properties) {
      return;
    }
    if (!formValues || !formValues.premium_payment) {
      return;
    }
    if (!formValues.premium_payment.settlement_due_date) {
      return;
    }
    if (formValues.premium_payment.due_to_ed_date) {
      return;
    }
    const cloned = cloneDeep(getFormState());
    const nextFormValues: FormValues = {
      ...cloned,
      premium_payment: {
        ...cloned.premium_payment,
        due_to_ed_date: moment(cloned.premium_payment?.settlement_due_date).subtract(10, 'days').toISOString(),
      },
    };
    setFormValues(nextFormValues);
  }, [schema.properties.premium_payment, formValues.premium_payment]);

  useEffect(() => {
    if (formValues.unapproved_security === 'No') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'client_disclaimer_signed');
      unset(nextFormValues, 'unapproved_security_name_section');
      setFormValues(nextFormValues);
    }
  }, [formValues.unapproved_security]);

  useEffect(() => {
    let clonedFormErrors = [...(formErrors ?? [])];
    if (formValues.client_disclaimer_signed === 'No') {
      clonedFormErrors.push({
        keyword: 'isWithDisclaimerWarning',
        dataPath: 'client_disclaimer_signed',
        path: 'client_disclaimer_signed',
        message: 'WARNING! Get the Client Disclaimer Letter signed and escalate this before you proceed further!',
      });
    } else {
      clonedFormErrors = [...(formErrors ?? []).filter((e) => e.keyword !== 'isWithDisclaimerWarning')];
    }
    setFormErrors(clonedFormErrors);
  }, [formValues.client_disclaimer_signed]);

  useEffect(() => {
    if (formValues.declared_to_lineslip === 'No') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'profit_commission');
      setFormValues(nextFormValues);
    }
  }, [formValues.declared_to_lineslip]);
};
